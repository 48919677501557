<template>
  <div class="address-search-page">
    <PageBanner
      background="alternative"
      heading="Tjänster du kan beställa"
      subheading="Här kan du se och jämföra alla tjänster som kan levereras till din adress."
      class="address-search-page-banner" />
    <AddressSearch
      class="address-search-page-component"
      :alternative="true" />
  </div>
</template>

<script>
import PageBanner from '@/components/views/PageBanner'
import AddressSearch from '@/components/address-search/AddressSearch'

export default {
  components: {
    AddressSearch,
    PageBanner
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.address-search-page {
  &-component {
    margin: 50px 0;
  }
}
</style>
